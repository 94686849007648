import { useState } from "react";
import { colors } from "../../../../constants";
import { Icon, IconTypes } from "../../../../shared/components";
import {
  Button,
  ButtonTypes,
  FlexContainer,
  FlexItem,
} from "../../../../shared/styled";
import { css } from "styled-components";

const VARIABLE_NAMES = "Variable Name";
const GENERATION_TOKENS = "Generate Tokens";

const InstructionContent = {
  [VARIABLE_NAMES]: (
    <FlexContainer
      flexDirection="column"
      gap="20px"
      dimensions={{ maxHeight: "500px" }}
      overflow={{ y: "auto" }}
    >
      <div>
        <b>Variable usage</b>
        <br />
        <br />
        <br />
        To ensure that every email is uniquely tailored to the specifics of each
        lead, we use <i>variables</i>. Variables represent specific values
        grouped by keywords, such as specifying where and when to include the
        lead's first name or shipment date.
        <br />
        <br />
        The following set of variables specifies which keyword corresponds to
        which value. To use it anywhere in the template just add variable in
        double curly brackets in this format: <code>{"{{variable}}"}</code>
        <br />
        <br />
        <b>NOTE!!</b> Make sure not to have extra spaces when defining variable
        usage!
        <br />
      </div>
      <FlexContainer flexDirection="column">
        <FlexContainer flexDirection="row">
          <b>List of available variables</b>
        </FlexContainer>
        <br />
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.id</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Unique identified for lead in our system
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.first_name</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>First name of the lead</FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.last_name</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>Last name of the lead</FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.contact.email</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Recieved contact email of the lead
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.contact.phone</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Recieved contact phone number of the lead
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.origin.city</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Origin city of the lead's route
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.origin.state</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Origin state of the lead's route
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.origin.postal_code</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Origin zip code of the lead's route
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.destination.city</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Destination city of the lead's route
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.destination.state</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Destination state of the lead's route
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.destination.postal_code</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Destination zip code of the lead's route
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.ship_date</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Shippment date of lead's order
          </FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.vehicle.make</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>Lead's vehicle make</FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.vehicle.model</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>Lead's vehicle model</FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.vehicle.year</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>Lead's vehicle year</FlexItem>
        </FlexContainer>
        <FlexContainer flexDirection="row">
          <FlexItem dimensions={{ flex: 1 }}>
            <b>lead.price</b>
          </FlexItem>
          <FlexItem dimensions={{ flex: 2 }}>
            Lead's route order arranged/selected price
          </FlexItem>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  ),
  [GENERATION_TOKENS]: <>Not Defined</>,
};

export const InstructionModal = ({ close }: { close(): void }) => {
  const [currentSegment, setSegment] =
    useState<keyof typeof InstructionContent>(VARIABLE_NAMES);

  return (
    <FlexContainer
      flexDirection="column"
      background={colors?.white?.clean}
      dimensions={{ minWidth: "300px", minHeight: "300px", maxWidth: "800px" }}
      padding="24px"
      gap="20px"
      border={{ radius: "24px" }}
    >
      <FlexContainer justifyContent="space-between">
        <FlexItem dimensions={{ flex: 1 }}>
          Email Template Instructions
        </FlexItem>
        <Button onClick={() => close && close()}>
          <Icon
            size="16px"
            type={IconTypes.close}
            color={colors?.white?.clean}
          />
        </Button>
      </FlexContainer>
      <FlexContainer gap="5px">
        {Object.keys(InstructionContent).map((instructionContent) => (
          <Button
            styleType={ButtonTypes.primary}
            onClick={() => setSegment(instructionContent as any)}
            disabled={currentSegment === instructionContent}
          >
            {instructionContent}
          </Button>
        ))}
      </FlexContainer>
      {InstructionContent[currentSegment]}
    </FlexContainer>
  );
};
