import { httpPost } from "../../utilities";

export interface HttpAdminInviteUserData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  assign_agent_roles: number[];
  is_admin: boolean;
  phone_number: string;
  is_marketing: boolean;
  messaging_email?: string;
}

export const httpAdminInviteUser = async (data: HttpAdminInviteUserData) => {
  return httpPost(`/admin/users/invite`, data);
}