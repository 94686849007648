import { NavigateFunction } from "react-router-dom";

import { Icon, IconTypes, Select, Toggle } from "../../../../shared/components";
import { TableProps } from "../../../../shared/components/Table/types/TableProps";
import { theme } from "../../../../constants";
import { User } from "../../../../models/user";
import { Badge, FlexRow } from "../../../../shared/styled";
import { ModalContextType } from "../../../../contexts";
import { UpdateUserAuthModal } from "./modals";
import { css } from "styled-components";
import toast from "react-hot-toast";

interface TableSchemaArgs {
  toggleStatus: (id: number, status: boolean) => void | Promise<void>;
  toggleGoogleAuthRequirement: (
    id: number,
    status: boolean
  ) => void | Promise<void>;
  navigate: NavigateFunction;
  openModal: ModalContextType["openModal"];
  closeModal: ModalContextType["closeModal"];
  deleteUser: (id: number) => void | Promise<void>;
}

export const TableSchema = ({
  toggleStatus,
  navigate,
  openModal,
  closeModal,
  deleteUser,
  toggleGoogleAuthRequirement,
}: TableSchemaArgs): TableProps<User>["columns"] => [
  {
    label: "No",
    attribute: "id",
    orderByAttribute: "id",
    styling: { dimensions: { maxWidth: "50px", width: "50px" } },
    render: (item) => item?.id || <>-</>,
  },
  {
    label: "Name",
    attribute: "first_name",
    orderByAttribute: "first_name",
    styling: { dimensions: { maxWidth: "200px", width: "200px" } },
    render: (item) => `${item?.first_name} ${item?.last_name}`,
  },
  {
    label: "Status",
    attribute: "is_disabled",
    orderByAttribute: "is_disabled",
    styling: { dimensions: { width: "100px", maxWidth: "100px" } },
    render: (item) => (
      <Toggle
        checked={!item?.is_disabled}
        onChange={async () => {
          if (!item) return;

          toggleStatus(item.id as number, !!item.is_disabled);
        }}
        backgroundColorChecked="#2CD06E"
        backgroundColorUnchecked="#E0E0E0"
        width={28}
        height={16}
        sliderHeight={12}
        sliderWidth={12}
        translate={9}
      />
    ),
  },
  {
    label: "GOOGLE",
    attribute: "requires_google_oauth",
    orderByAttribute: "requires_google_oauth",
    styling: { dimensions: { width: "100px", maxWidth: "100px" } },
    render: (item) => (
      <Toggle
        checked={item?.requires_google_oauth ?? false}
        onChange={async () => {
          if (!item) return;

          console.log('item?.requires_google_oauth', item?.requires_google_oauth)

          if (!item.messaging_email) {
            toast.error("Must have 'messaging email' set first!", {
              style: {
                border: `1px solid ${theme.colors.danger}`,
                padding: "16px",
                boxShadow: "none",
                borderRadius: "24px",
              },
              iconTheme: {
                primary: `${theme.colors.danger}`,
                secondary: `${theme.colors.clean}`,
              },
            });
            return;
          }

          toggleGoogleAuthRequirement(
            item.id as number,
            !(item.requires_google_oauth ?? false)
          );
        }}
        backgroundColorChecked="#2CD06E"
        backgroundColorUnchecked="#E0E0E0"
        width={28}
        height={16}
        sliderHeight={12}
        sliderWidth={12}
        translate={9}
      />
    ),
  },

  {
    label: "Role",
    attribute: "roles",
    styling: {
      dimensions: { maxWidth: "400px", width: "400px" },
      flexDirection: "column",
      overflow: { y: "auto" },
    },
    render: (item) => (
      <FlexRow gap="5px">
        {item?.roles?.map((role) => (
          <Badge>{role}</Badge>
        ))}
        {item?.agent_roles?.map((agentRole) => (
          <Badge>{agentRole.name}</Badge>
        ))}
      </FlexRow>
    ),
  },
  {
    label: "Action",
    styling: { dimensions: { width: "100px", maxWidth: "100px" } },
    render: (item) => (
      <>
        <Select
          transparent={true}
          placeholder={
            <Icon
              type={IconTypes.doner}
              color={theme.colors.disabled}
              size="18px"
            />
          }
          hasCarrot={false}
          options={[
            { label: "Edit", value: "route,/admin/manage/:email" },
            { label: "Update auth", value: "action,updateAuthModal" },
            { label: "Delete", value: "action,delete", textColor: "#CC3434" },
          ]}
          closeOnSelectOption={true}
          enableHoverEffect={true}
          justifyContent="center"
          onTop={true}
          style={{
            valueWrapper: {
              dimensions: { width: "120px", height: "32px" },
              css: css`
                border: none;
              `,
            },
          }}
          setValue={(value: string) => {
            const [type, data] = value?.split(",");
            if (type === "route") {
              if (item?.email === undefined) return;
              const routeData = data.includes(":email")
                ? data.replace(":email", item?.email)
                : data;
              navigate(routeData);
              return;
            }

            if (data === "updateAuthModal") {
              openModal("update-user-auth", UpdateUserAuthModal, {
                close: () => closeModal("update-user-auth"),
                user: item,
              });
              return;
            }

            if (data === "delete") {
              if (item?.id !== undefined) {
                deleteUser(item.id);
              }
              return;
            }
          }}
        />
      </>
    ),
  },
];
